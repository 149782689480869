<template>
    <div
        :class="{ offsetTop }"
        class="reminders"
    >
        <div
            v-if="!hideTop"
            class="top flex items-end"
        >
            <SmallHeading heading="Kom ihåg" />
        </div>

        <div
            v-if="reminders && reminders.length > 0"
            class="list whiteBox"
        >
            <Reminder
                v-for="reminder in reminders"
                :key="reminder.id"
                :title="reminder.title"
                :date="reminder.date"
                :url="reminder.url"
            />
        </div>

        <p
            v-else-if="loaded"
            class="whiteBox noReminders"
        >
            Just nu finns det inga kommande aktiviteter som vi vill påminna dig om.
        </p>
    </div>
</template>

<script>
import ReminderService from '@/services/ReminderService';
import Reminder from './Reminder';
import SmallHeading from '../ui/SmallHeading';

export default {
    components: {
        Reminder,
        SmallHeading
    },

    props: {
        userId: {
            type: Number,
            default: null
        },

        offsetTop: {
            type: Boolean,
            default: false
        },

        hideTop: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        loaded: false,
        reminders: []
    }),

    async created () {
        const res = await ReminderService.get({ userId: this.userId });

        this.reminders = res.data;

        this.loaded = true;
    }
};
</script>

<style lang="postcss" scoped>
.top {
    margin-bottom: 2.5rem;
    min-height: 4.4rem;
}

.offsetTop .top {
    min-height: 0;
    margin-top: -4.7rem;
}

.noReminders {
    padding: 2rem 3rem;
    font-size: 1.3rem;
    @media (--laptop) {
        padding: 1.5rem 2.2rem;
    }
    @media (--largeTablet) {
        padding: 1.5rem 1.8rem;
    }
}
</style>
