<template>
    <LargeColumnWithSidebar>
        <div
            v-if="authUser.mentors && authUser.mentors.length > 0"
            class="mentors flex flex-wrap"
        >
            <div
                v-for="mentor in authUser.mentors"
                :key="mentor.id"
                class="column"
            >
                <div class="mentor whiteBox flex items-center">
                    <Avatar
                        :name="mentor.name"
                        :image="mentor.image"
                        class="flex-shrink-0"
                    />

                    <div class="textHolder">
                        <h2 class="name">
                            {{ mentor.name }}
                        </h2>

                        <p class="title">
                            Mentor
                        </p>

                        <p class="contact break-all">
                            <b>Tel:</b> <a :href="`tel:${mentor.phone}`">{{ mentor.phone }}</a>
                        </p>

                        <p class="contact break-all">
                            <b>E-post:</b> <a :href="`mailto:${mentor.phone}`">{{ mentor.email }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <template v-slot:sidebar>
            <Reminders offset-top />
        </template>
    </LargeColumnWithSidebar>
</template>

<script>
import LargeColumnWithSidebar from '@/components/layout/LargeColumnWithSidebar';
import Reminders from '@/components/reminders/Reminders';
import Avatar from '@/components/ui/Avatar';
import { mapGetters } from 'vuex';

export default {
    components: {
        LargeColumnWithSidebar,
        Reminders,
        Avatar
    },

    computed: {
        ...mapGetters('auth', ['authUser'])
    }
};
</script>

<style lang="postcss" scoped>
.largeColumnWithSidebar {
    padding-top: 4.7rem;
    @media (--mobile) {
        padding-top: 0;
    }
}

.mentors {
    margin: -1rem;
}

.column {
    width: 50%;
    padding: 1rem;
    @media (--tablet) {
        width: 100%;
    }
}

.mentor {
    padding: 3rem;
    @media (--mobile) {
        padding: 2rem 1.5rem;
    }
}

.avatar {
    font-size: 1.45rem;
    margin-right: 2.5rem;
    @media (--mobile) {
        font-size: 1.2rem;
        margin-right: 1.8rem;
    }
}

.name {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.title,
.contact {
    color: #7f7f7f;
    letter-spacing: 0.03rem;
    font-size: 1.6rem;
}

.title {
    margin-bottom: 1.2rem;
}

.contact {
    margin-bottom: 0;
}

.contact b {
    color: var(--secondaryColor);
}
</style>
