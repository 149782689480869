<template>
    <div class="largeColumnWithSidebar flex">
        <div class="largeColumnWrapper column w-full">
            <slot />
        </div>

        <div
            v-if="showSidebar"
            class="sidebarWrapper column flex-shrink-0"
        >
            <slot name="sidebar" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hideSidebarSmallDevice: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({
        showSidebar: false
    }),

    mounted () {
        if (!this.hideSidebarSmallDevice) {
            this.showSidebar = true;
            return;
        }

        const mobileMediaQueryWatcher = window.matchMedia('(max-width: 600px)');

        const screenTest = (mq) => {
            this.showSidebar = !mq.matches;
        };

        screenTest(mobileMediaQueryWatcher);
        mobileMediaQueryWatcher.addListener(screenTest);

        this.$once('hook:beforeDestroy', () => {
            mobileMediaQueryWatcher.removeListener(screenTest); ;
        });
    }
};
</script>

<style lang="postcss" scoped>
.largeColumnWithSidebar {
    @media (--mobile) {
        flex-wrap: wrap;
    }
}

.column {
    @media (--tablet) {
        width: 100%;
    }
}

.sidebarWrapper {
    width: 21.3%;
    margin-left: 4rem;
    @media (--laptop) {
        margin-left: 3rem;
    }
    @media (--tablet) {
        margin-left: 2rem;
        width: 19rem;
    }
    @media (--mediumTablet) {
        width: 17rem;
    }
    @media (--smallTablet) {
        width: 22rem;
    }
    @media (--mobile) {
        width: 100%;
        margin-top: 5rem;
        margin-left: 0;
    }
}
</style>
