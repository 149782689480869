<template>
    <component
        :is="url ? 'a' : 'div'"
        :href="url ? url : null"
        :target="url ? '_blank' : null"
        class="reminder block"
    >
        <SmallHeading :heading="title" />

        <p class="date">
            <span :class="{ 'mr-2': url }">{{ formattedDate }}</span>

            <span
                v-if="url"
                class="readMore"
            >
                Läs mer
            </span>
        </p>
    </component>
</template>

<script>
import SmallHeading from '../ui/SmallHeading';

export default {
    components: {
        SmallHeading
    },

    props: {
        title: {
            type: String,
            required: true
        },

        date: {
            type: String,
            required: true
        },

        url: {
            type: String,
            default: null
        }
    },

    computed: {
        formattedDate () {
            return (new Date(this.date)).toLocaleDateString('sv-SE', {
                weekday: 'long',
                day: 'numeric',
                month: 'long'
            });
        }
    }
};
</script>

<style lang="postcss" scoped>
.reminder {
    padding: 2rem 3rem;
    border-bottom: 1px solid #eee;
    @media (--laptop) {
        padding: 1.5rem 2.2rem;
    }
    @media (--largeTablet) {
        padding: 1.5rem 1.8rem;
    }
}

a.reminder:hover {
    background-color: #f5f5f5;
}

.reminder .smallHeading {
    margin-bottom: 0.2rem;
    @media (--laptop) {
        font-size: 1.5rem;
    }
    @media (--largeTablet) {
        font-size: 1.4rem;
    }
    @media (--tablet) {
        font-size: 1.3rem;
    }
    @media (--mobile) {
        font-size: 1.5rem;
        margin-bottom: 0.3rem;
    }
}

.date {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 0.025rem;
    color: var(--primaryColor);
    @media (--laptop) {
        font-size: 1.1rem;
    }
    @media (--tablet) {
        font-size: 1rem;
    }
    @media (--mobile) {
        font-size: 1.15rem;
    }

    &::first-letter {
        text-transform: uppercase;
    }
}

.readMore {
    font-size: 0.9em;
    font-weight: bold;
    display: inline-block;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        background-color: var(--primaryColor);
        opacity: 0.5;
    }
}
</style>
