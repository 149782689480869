import * as API from './API.js';

export default {
    get (payload) {
        return API.apiClient.get('/reminders', {
            params: payload
        });
    },

    today () {
        return API.apiClient.get('/reminders/today');
    }
};
