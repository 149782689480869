<template>
    <h4 class="smallHeading">
        {{ heading }}
    </h4>
</template>

<script>
export default {
    props: {
        heading: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="postcss" scoped>
.smallHeading {
    color: var(--secondaryColor);
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    line-height: 1.4;
    margin-bottom: 0;
}
</style>
